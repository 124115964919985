import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    outBox : {
        // minHeight: 'calc(100vh - 64px)',
        margin: '0 20px',
        marginBottom: '40px'
    },

}));
