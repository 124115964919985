import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from '../../assets/images/Carousel/image1.jpeg'
import image2 from '../../assets/images/Carousel/image2.jpeg'
import image3 from '../../assets/images/Carousel/image3.jpeg'
import image4 from '../../assets/images/Carousel/image4.jpeg'
import useStyles from './styles';
import { Box } from '@mui/system';

export default function CarouselComponent () {
  const classes = useStyles();
  const images = [image1, image2, image3, image4]
  return (
    <Box>
      <Slider
        // dots={true}
        autoplay={true}
        arrows={false}
        pauseOnHover={false}
        useTransform={false}
        // width='100vw'
        // showArrows={true}
        // showStatus={false}
        // showThumbs={false}
        // useKeyboardArrows
        // autoPlay={100}
        // transitionTime={1000}
        // infiniteLoop
        // swipeable={false}
        // stopOnHover={false}
      >
      { 
        images.map((image, idx) => (
          <div key={idx}>
            <img src={image} className={classes.carouselImg} alt='carouselImg'/>
          </div>
        ))
      }
      </Slider>
    </Box>
  )
}
