// import { Button } from '@mui/material'
import React from 'react'
// import { useHistory } from 'react-router-dom'
import About from '../Components/About/About'
import CarouselComponent from '../Components/Carousel/Carousel'
// import InfoSection from '../Components/InfoSection/InfoSection'
// import PopularProducts from '../Components/PopularProducts/PopularProducts'
import Reviews from '../Components/Reviews/Reviews'

export default function LandingPage({ reviewRef, aboutRef }) {
  console.log(aboutRef)
  
  return (
    <div>
      {/* <Navbar /> */}
      {/* <div style={{height: '100px'}} onClick={() => {scrollToRef(aboutRef)}}></div> */}
      <CarouselComponent />
      <About inRef={aboutRef}/>
      {/* <PopularProducts /> */}
      {/* <InfoSection /> */}
      <Reviews inRef={reviewRef}/>
    </div>
  )
}
