import { collection, getDocs, getFirestore, query, where } from '@firebase/firestore'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProductCard from '../ProductCard/ProductCard'


export default function Products({ collectionName, title }) {
  const [products, setProducts] = useState({})
  const [loading, setLoading] = useState(true)
  const db = getFirestore()

  useEffect(() => {
    const initProducts = async () => {
      setLoading(true)
      const formQuery = query(collection(db, collectionName), where("active", "==", true))
      let dbCollections = await getDocs(formQuery)

      // const 
      let docs = {}

      dbCollections.forEach((doc) => {
        const data = doc.data()
        // get lowest price for document
        let lowestPrice = Infinity; // set the initial value to Infinity so any price will be lower
        let lowestPriceStr = ""
        for (let key in data.prices) {
          let price = parseFloat(data.prices[key].price); // convert the price to a float
          if (price < lowestPrice) {
            lowestPrice = price;
            lowestPriceStr = data.prices[key].price
          }
        }

        docs[doc.id] = { name: data.name, image: data.thumbnail, price: lowestPriceStr }


      })
      setProducts(docs)
      setLoading(false)
    }
    initProducts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // console.log(products)

  function sortKeys(arr) {
    return arr.slice().sort((a, b) => a - b);
  }

  return (
    <Box>
      {Object.keys(products).length === 0 && loading && <LinearProgress style={{ backgroundColor: '#dbb79373' }} />}
      <Box sx={{ margin: '0 20px' }}>
        <Typography variant='h4' align='center' gutterBottom marginTop={'20px'} marginBottom={'35px'}>
          {title}
        </Typography>
        {
          Object.keys(products).length === 0 && !loading && <h2 style={{textAlign: 'center'}}>
            Coming soon...
          </h2> 
        }
        <Box marginRight={'20px'} marginLeft={'20px'} paddingBottom={'20px'}>
          <Grid container spacing={4}>
            {
              sortKeys(Object.keys(products)).map((key, idx) => (
                <Grid key={idx} item xs={12} sm={6} md={6} lg={3} >
                  <ProductCard 
                    title={products[key].name} 
                    image={products[key].image} 
                    price={products[key].price}
                    productLink={"/products/" + collectionName + "/" + key}
                  />
                </Grid>
              ))
            }
          </Grid>
          </Box>
        {/* {products ? products["basmallah"].price : null} */}
      </Box>
    </Box>
  )
}
