import React, { useEffect, useState } from 'react'
import ShippingTable from '../ShippingTable/ShippingTable';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { collection, deleteDoc, doc, getDocs, getFirestore } from '@firebase/firestore';
import useStyles from './styles';
import { useHistory } from 'react-router';
import { productTypes } from '../../productTypes';

const ConsoleShipping = () => {
  const db = getFirestore()
  const history = useHistory()
  const [productType, setProductType] = useState(productTypes[0].value);

  const [sizes, setSizes] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fun = async () => {
      setLoading(true)
      const db = getFirestore()
      const data = await getDocs(collection(db, `${productType}_shipping_sizes`))
      const allSizes = (data.docs.map((doc) => ({ ...doc.data(), name: doc.id  })))
      setSizes(allSizes)
      setLoading(false)
    }

    fun()
  }, [productType])

  const handleSizeDelete = async (size, idx) => {
    await deleteDoc(doc(db, `${productType}_shipping_sizes/${size.name}`))
    let filteredSizes = sizes.filter(item => item.name !== size.name)
    setSizes(filteredSizes)
  }

  const handleSizeUpdate = (size) => {
    history.push(`/console/shipping/edit/${productType}/${size.name}`)
  }

  const classes = useStyles()

  return (
    <Box className={classes.mainBody}>
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Shipping Sizes
      </Typography>
      <Box paddingBottom={'20px'}>
        <TextField
          name="productType"
          margin="dense"
          id="standard-select-product"
          select
          label="Product Type"
          fullWidth
          value={productType}
          onChange={e => setProductType(e.target.value)}
        >
          {productTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                  {option.label}
              </MenuItem>
          ))}
        </TextField>
      </Box>
      <ShippingTable sizes={sizes} loading={loading} handleSizeDelete={handleSizeDelete} handleSizeUpdate={handleSizeUpdate} />
    </Box>
    
  )
}

export default ConsoleShipping;