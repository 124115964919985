import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useHistory } from 'react-router'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PublicIcon from '@mui/icons-material/Public';
import withAuthorization from '../HOC/withAuthorization'
import ConsoleShipping from '../ConsoleComponents/ConsoleShipping/ConsoleShipping'

const ConsoleShippingPage = () => {
  const history = useHistory()
  
  return (
    <Box>
      <ConsoleShipping />
      <Button
        startIcon={<AddCircleOutlineOutlinedIcon />}
        sx={{
          position: 'fixed', 
          bottom: '60px', 
          left: '10px',
          minWidth: '215px'
        }}
        color='success'
        variant='contained'
        onClick={() => { history.push('/console/shipping/add') }}
      >
        Add Shipping Size
      </Button>
      <Button
        startIcon={<PublicIcon />}
        
        sx={{
          position: 'fixed', 
          bottom: '10px', 
          left: '10px',
          minWidth: '215px'
        }}
        color='warning'
        variant='contained'
        onClick={() => { history.push('/console/shipping/zones') }}
      >
        Edit Country Zone
      </Button>
    </Box>
  )
}

export default withAuthorization(ConsoleShippingPage)