import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  mainBody: {
    margin: '10px 60px',
    marginBottom: '100px'
  },
  '@media (max-width:780px)': {
    mainBody: {
      margin: '10px 10px'
    },
  }
}));
