import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  carouselImgContainer: {
    overflow: 'hidden',
    transform: 'scale(1)',
    transition: 'all 1s ease'
  },

  carouselImg: {
    // maxHeight: 'calc(100vh - 64px)',
    // minWidth: '100vw !important',
    objectFit: 'fit',
  },

  skeletonImg: {
    height: 'var(--height-width)',
  }

}));
