import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  '@font-face': {
    fontFamily: 'MyDosis',
    src: "url('../../assets/fonts/Dosis.ttf')"
  },
  divider: {
    width: '145px',
  },
  textAreaCont: {
    minHeight: 'calc(100vh)',
    margin: '0 20px',
    display: 'flex',
    borderBottom: '1px #979797 solid'
  },
  textArea: {
    maxWidth: '600px',
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'

  },
  text: {
    fontFamily: '"MyDosis" sans-serif',
    textAlign: 'center',
    fontSize: '16pt'
  }
  
  
}));
