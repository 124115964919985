import { Box } from '@mui/system'
import React from 'react'
import AddCoupons from '../ConsoleComponents/AddCoupons/AddCoupons'
import withAuthorization from '../HOC/withAuthorization'

const ConsoleNewCouponPage = () => {
  return (
    <Box>
      <AddCoupons />
    </Box>
  )
}

export default withAuthorization(ConsoleNewCouponPage)