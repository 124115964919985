import React from 'react'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { useHistory } from 'react-router'
import CouponTable from '../ConsoleComponents/CouponTable/CouponTable'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import withAuthorization from '../HOC/withAuthorization'

const ConsoleCouponPage = () => {
  const history = useHistory()

  return (
    <Box>
      <CouponTable />
      <Button
        startIcon={<AddCircleOutlineOutlinedIcon />}
        sx={{
          position: 'fixed', 
          bottom: '10px', 
          left: '10px'
        }}
        color='success'
        variant='contained'
        onClick={() => { history.push('/console/coupons/add') }}
      >
        Add Coupon
      </Button>
    </Box>
  )
}

export default withAuthorization(ConsoleCouponPage)