import { Box } from '@mui/system'
import React from 'react'
import AddShipping from '../ConsoleComponents/AddShipping/AddShipping'
import withAuthorization from '../HOC/withAuthorization'

const ConsoleNewShippingPage = () => {
  return (
    <Box>
      <AddShipping />
    </Box>
  )
}

export default withAuthorization(ConsoleNewShippingPage)