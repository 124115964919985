import React from 'react'
import Console from '../ConsoleComponents/Console/Console'
import withAuthorization from '../HOC/withAuthorization'
import { Box } from '@mui/material'

const ConsolePage = () => {

  return (
    <Box>
      <Console />
    </Box>
  )
}

export default withAuthorization(ConsolePage)