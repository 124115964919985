import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#9A7B4F"),
  backgroundColor: "#9A7B4F",
  '&:hover': {
    backgroundColor: "#856a44",
  },
}));

export default ColorButton