import React from "react";
import { useDropzone } from "react-dropzone";
import { Button, Grid, Typography } from "@mui/material";
import ColorButton from "../../Components/PrimaryButton/PrimaryButton";

export default function ImagesDropzone({ setImageList, limit }) {
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const newImages = Array.from(acceptedFiles).map((file) => {
      return {
          file: file,
          fileName: file.name,
          status: "CREATED",
          downloadURL: URL.createObjectURL(file),
          description: "",
      };
      });

    setImageList((prevState) => [...prevState, ...newImages].slice(0, limit));
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {'image/*': ['.jpeg', '.jpg', '.png']},
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography align="center">
            {isDragActive
              ? "Drop Images here"
              : "Drag and drop Images here, or:"}
            </Typography>
          </Grid>
          <Grid item>
            <ColorButton onClick={open} variant="contained" color="primary">
              Select Images
            </ColorButton>
          </Grid>
          {/* <Box sx={{fontStyle: 'italic', fontWeight: 'bold'}}> */}
            {
              limit <= 3 ? 
              null :
            <Typography mt={'10px'} align="center" sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              The first image in the list of images will be the thumbnail
            </Typography>
            }
            
          {/* </Box> */}

        </Grid>
      </Grid>
    </div>
  );
}