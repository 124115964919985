import React from 'react'
import withAuthorization from '../HOC/withAuthorization'
import { Box } from '@mui/material'
import EditProduct from '../ConsoleComponents/EditProduct/EditProduct'

const ConsoleEditProductPage = () => {
  return (
    <Box>
      <EditProduct />
    </Box>
  )
}

export default withAuthorization(ConsoleEditProductPage)
