import React, { useState, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SkeletonLoadImage = ({ src, alt, width, className, lazyLoad = true }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    if (lazyLoad) {
      const options = {
        root: null, // viewport
        rootMargin: '0px',
        threshold: 0.1, // 10% of the image must be visible
      };

      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          // Set the src of the image element when it becomes visible
          imageRef.current.src = src;
          imageRef.current.onload = () => {
            setImageLoaded(true);
          };

          observer.unobserve(imageRef.current);
        }
      }, options);

      observer.observe(imageRef.current);
      const currentImageRef = imageRef.current;
      
      return () => {
        observer.unobserve(currentImageRef);
      };
    } else {
      // no load on entry. just load.
      imageRef.current.src = src;
      imageRef.current.onload = () => {
        setImageLoaded(true);
      };
    }
  }, [src, lazyLoad]);

  return (
    <div
      style={{
        position: 'relative',
        width: width,
        maxHeight: width,
        paddingBottom: '100%', // Set aspect ratio (1:1) here
      }}
    >
      <img
        ref={imageRef}
        alt={alt}
        height={width}
        style={{
          position: 'absolute',
          width: `${width ? width : '100%'}`,
          height: `${width ? width : '100%'}`,
          objectFit: 'cover',
          opacity: imageLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
      <Skeleton
        style={{
          width: `${width ? width : '100%'}`,
          height: `${width ? width : '100%'}`,
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: imageLoaded ? 0 : 1,
          zIndex: 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
    </div>
  );
};
