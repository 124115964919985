import React, { useState, useRef } from 'react';

import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './FirebaseApp/FirebaseApp'

import './App.css';
// import LandingPage from './Pages/LandingPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import ConsolePage from './Pages/ConsolePage';
import { AuthProvider } from './Providers/AuthProvider/AuthProvider';
import ConsoleNewProductPage from './Pages/ConsoleNewProductPage';
import ConsoleCouponPage from './Pages/ConsoleCouponPage';
import ConsoleShippingPage from './Pages/ConsoleShippingPage';
import ConsoleNewCouponPage from './Pages/ConsoleNewCouponPage';
import ConsoleNewShippingPage from './Pages/ConsoleNewShippingPage';
import ConsoleProductPage from './Pages/ConsoleProductPage';
import ConsoleEditShippingPage from './Pages/ConsoleEditShippingPage';
import LandingPage from './Pages/LandingPage';
import ProductPage from './Pages/ProductPage';
import ShoppingCart from './Pages/ShoppingCart';
import ConsoleShippingZonePage from './Pages/ConsoleShippingZonePage';
import PrintsPage from './Pages/PrintsPage';
import Navbar from './Components/Navbar/Navbar';
import ContactPage from './Pages/ContactPage';
import CustomOrderPage from './Pages/CustomOrderPage';
import { SnackbarProvider } from './Providers/SnackbarProvider/SnackbarProvider';
import NotFound from './Pages/NotFound';
import JournalsPage from './Pages/JournalsPage';
import OriginalsPage from './Pages/OriginalsPage';
import ConsoleEditProductPage from './Pages/ConsoleEditProductPage';
import Success from './Pages/Success';


initializeApp(firebaseConfig)

// TODO: make a 404 page and all paths that are not matched should be sent to that page.
function App() {
  // Cart operations
  let [cartNum, setCartNum] = useState(
    JSON.parse(localStorage.getItem("items")) ? 
    JSON.parse(localStorage.getItem("items")).length : 
    0
  )
  const addToCartNum = () => {
    setCartNum((prevValue) => prevValue + 1)
    
    // Restart successItems if it exists
    if (localStorage.getItem("successItems")) {
      localStorage.removeItem("successItems")
    }
  }

  const removeFromCartNum = () => {
    setCartNum((prevValue) => prevValue - 1)
  }

  const removeAllFromCartNum = () => {
    setCartNum(0)
  }
  
  // References
  const aboutRef = useRef(null);
  const reviewRef = useRef(null);

  return (
    <AuthProvider>
      <SnackbarProvider>
        <Router>
          <Navbar cartNum={cartNum} aboutRef={aboutRef} reviewRef={reviewRef} />

          <Switch>
            <Route exact path="/">
              <LandingPage reviewRef={reviewRef} aboutRef={aboutRef}/>
              {/* <h1 style={{textAlign: 'center'}}>
                Coming soon :)
              </h1> */}
            </Route>

            <Route exact path="/products/originals">
              <OriginalsPage />
            </Route>

            <Route exact path="/products/prints">
              <PrintsPage />
            </Route>

            <Route exact path="/products/journals">
              <JournalsPage />
            </Route>

            <Route exact path="/products/:productType/:productName">
              <ProductPage addToCartNum={addToCartNum}/>
            </Route>

            <Route exact path="/cart">
              <ShoppingCart removeFromCartNum={removeFromCartNum}/>
            </Route>

            <Route exact path="/contact">
              <ContactPage />
            </Route>

            <Route exact path="/order">
              <CustomOrderPage />
            </Route>

            <Route exact path="/success">
              <Success removeAllFromCartNum={removeAllFromCartNum} />
            </Route>
          
          
            {/* admin only part of site */}
            <Route exact path="/console">
              <ConsolePage />
            </Route>
          
            <Route exact path="/console/products">
              <ConsoleProductPage />
            </Route>
            <Route exact path="/console/shipping">
              <ConsoleShippingPage />
            </Route>
            <Route exact path="/console/coupons">
              <ConsoleCouponPage />
            </Route>
            <Route exact path="/console/products/add">
              <ConsoleNewProductPage />
            </Route>
            <Route exact path="/console/products/edit/:type/:name">
              <ConsoleEditProductPage />
            </Route>
            <Route exact path="/console/shipping/add">
              <ConsoleNewShippingPage />
            </Route>
            <Route exact path="/console/shipping/zones">
              <ConsoleShippingZonePage />
            </Route>
            <Route exact path="/console/coupons/add">
              <ConsoleNewCouponPage />
            </Route>
            <Route exact path="/console/shipping/edit/:type/:name">
              <ConsoleEditShippingPage />
            </Route>

            <Route exact path="/*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
