import React from 'react'
import { Box } from '@mui/material'
import Products from '../Components/Products/Products'

export default function OriginalsPage() {
  return (
    <Box>
      <Products title={"Originals"} collectionName={"originals"}/>
      
    </Box>
  )
}
