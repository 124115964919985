/* eslint-disable no-unused-vars */
import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useStyles from './styles';
import CreateIcon from '@mui/icons-material/Create';
import React, { useEffect, useState } from 'react'
import { doc, setDoc, getFirestore, getDoc, collection } from "firebase/firestore";
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import { NumericFormatCustom } from '../../Components/NumericFormatCustom/NumericFormatCustom';
import { useSnackbar } from '../../Providers/SnackbarProvider/SnackbarProvider';
import { productTypes } from '../../productTypes';

export default function EditShipping () {
  const { type, name } = useParams()

  const [disabled, setDisabled] = useState(true)
  const [size, setSize] = useState(name)
  const [basePrice, setBasePrice] = useState("")
  const [EU, setEU] = useState("")
  const [WZ1, setWZ1] = useState("")
  const [WZ2, setWZ2] = useState("")
  const [WZ3, setWZ3] = useState("")

  const db = getFirestore()
  const history = useHistory()
  const snackbar = useSnackbar()

  const classes = useStyles()
  
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setDisabled(true)
    // Need some validation
    await setDoc(doc(db, `${type}_shipping_sizes`, `${size}`), {
      BP: parseFloat(basePrice).toFixed(2),
      EU: parseFloat(EU).toFixed(2),
      WZ1: parseFloat(WZ1).toFixed(2),
      WZ2: parseFloat(WZ2).toFixed(2),
      WZ3: parseFloat(WZ3).toFixed(2)
    })
    setDisabled(false)
    history.push('/console/shipping')

    snackbar.showSnackbar({
      severity: 'success',
      message: 'Successfully edited shipping size',
      autoHideDuration: 2000,
    })
  }

  // const validate = () => {
    
  // }

  useEffect(() => {
    const fun = async () => {
      const data = await getDoc(doc(db, `${type}_shipping_sizes/${name}`))
      const shippingSize = data.data()
      setBasePrice(shippingSize.BP)
      setEU(shippingSize.EU)
      setWZ1(shippingSize.WZ1)
      setWZ2(shippingSize.WZ2)
      setWZ3(shippingSize.WZ3)
      setDisabled(false)
    }

    fun()
  }, [])

  return (
    <Box className={classes.mainBody} onSubmit={handleSubmit} component="form">
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Edit Shipping Size
      </Typography>
      <Stack spacing={4}>
        <TextField
          disabled={true}
          required
          name="productType"
          margin="dense"
          id="productType"
          select
          label="Product Type"
          fullWidth
          value={type}
        >
          <MenuItem key={type} value={type}>
            {productTypes.find(item => item.value === type)['label']}
          </MenuItem>
        </TextField>


        <TextField
          disabled={true}
          required
          name="sizeName"
          margin="dense"
          id="sizeName"
          autoComplete='off'
          label="Size Name"
          value={size}
          onChange={e => setSize(e.target.value)}
          fullWidth
        />

        <TextField
          disabled={disabled}
          name="price"
          required
          label="Base Shipping Price"
          variant="outlined"
          value={basePrice}
          onChange={setBasePrice}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        <TextField
          disabled={disabled}
          name="price"
          required
          label="European Shipping Price"
          variant="outlined"
          value={EU}
          onChange={setEU}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        

        <TextField
          disabled={disabled}
          name="WZ1"
          required
          label="World Zone 1"
          variant="outlined"
          value={WZ1}
          onChange={setWZ1}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        <TextField
          disabled={disabled}
          name="WZ2"
          required
          label="World Zone 2"
          variant="outlined"
          value={WZ2}
          onChange={setWZ2}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />
        
        <TextField
          disabled={disabled}
          name="WZ3"
          required
          label="World Zone 3"
          variant="outlined"
          value={WZ3}
          onChange={setWZ3}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        <Button
          disabled={disabled}
          fullWidth 
          color="primary" 
          variant="contained" 
          startIcon={<CreateIcon />}
          sx={{
            marginTop: '20px',
            marginBottom: '20px'
          }}
          type='submit'
        >
          Edit Shipping Size
        </Button>
      </Stack>

    </Box>
  )
}
