import { Box } from '@mui/system'
import useStyles from './styles';
import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, getDocs, getFirestore, deleteDoc, doc } from '@firebase/firestore';

export default function ShippingTable ({ sizes, loading, handleSizeDelete, handleSizeUpdate }) {
  const classes = useStyles()

  return (
    <Box className={classes.mainBody}>
      {
        loading 
        ? <Box className={classes.loading}>
          <CircularProgress />
        </Box>
        : <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Shipping Size</TableCell>
                <TableCell align="left">Base Price</TableCell>
                <TableCell align="left">Europe</TableCell>
                <TableCell align="left">World Zone 1</TableCell>
                <TableCell align="left">World Zone 2</TableCell>
                <TableCell align="left">World Zone 3</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sizes.map((size, idx) => (
                <TableRow key={idx}>
                  <TableCell align="left">{size.name}</TableCell>
                  <TableCell align="left">£{parseFloat(size.BP).toFixed(2)}</TableCell>
                  <TableCell align="left">£{parseFloat(size.EU).toFixed(2)}</TableCell>
                  <TableCell align="left">£{parseFloat(size.WZ1).toFixed(2)}</TableCell>
                  <TableCell align="left">£{parseFloat(size.WZ2).toFixed(2)}</TableCell>
                  <TableCell align="left">£{parseFloat(size.WZ3).toFixed(2)}</TableCell>
                  <TableCell align="left">
                    <Stack direction="row" spacing={1}>
                      <Button
                        color='info'
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => { handleSizeUpdate(size) } }
                        fullWidth
                      >
                        Edit
                      </Button>
                      {/* <Button
                        color='error'
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => { handleSizeDelete(size, idx) } }
                        fullWidth
                      >
                        Delete
                      </Button> */}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      {
        (sizes.length === 0 && !loading) && <Typography marginTop={2} className={classes.loading}> There are no shipping sizes yet </Typography>
      }
    </Box>
  )
}
