import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  '@font-face': {
    fontFamily: 'Maharlika',
    src: 'url(../../assets/fonts/Maharlika.ttf)'
  },
  navbarContainer: {
    width: '100vw',
    height: '64px',
    backgroundColor: '#DBB793',
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '64px',
    fontSize: '26pt',
    fontFamily: 'Maharlika',
    letterSpacing: '-2px',
    position: 'fixed',
    color: 'white',
    zIndex: '9999'
  },
  emptySpace: {
    width: '100vw',
    height: '64px',
  },
  logoText: {
    marginLeft: '60px',
    cursor: 'pointer'
  },
  signOutButton: {
    lineHeight: '55px',
    marginRight: '60px',
    color: 'white',
  },
  '@media (max-width:780px)': {
    navbarContainer: {
      fontSize: '22pt',
    },
    logoText: {
      marginLeft: '10px'
    },
    signOutButton: {
      marginRight: '10px',
    },
  }
}));
