import { Box } from '@mui/material'
import React, { useState } from 'react'
import withAuthorization from '../HOC/withAuthorization'
import ConsoleProducts from '../ConsoleComponents/ConsoleProducts/ConsoleProducts'

const ConsoleProductPage = () => {
  const [loading, setLoading] = useState(true)

  return (
    <Box>
      <ConsoleProducts loading={loading} setLoading={setLoading}/>
    </Box>
  )
}

export default withAuthorization(ConsoleProductPage)