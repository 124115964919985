import React from 'react'
import { useLocation } from 'react-router-dom';
import ConsoleNavbar from '../../ConsoleComponents/ConsoleNavbar/ConsoleNavbar';
import CustomerNavbar from '../CustomerNavbar/CustomerNavbar';

export default function Navbar ({ cartNum, aboutRef, reviewRef }) {
  const location = useLocation()
  const isConsolePage = location.pathname.startsWith('/console');

  return (
      isConsolePage ? (
        <ConsoleNavbar />
      ) : (
        <CustomerNavbar cartNum={cartNum} aboutRef={aboutRef} reviewRef={reviewRef}/>
      )
  )
}
