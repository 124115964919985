import { Box } from '@mui/material'
import React from 'react'
// import ProductReviewCard from '../ProductReviewCard/ProductReviewCard'
import ProductReviewCard from '../ProductReviewCard/ProductReviewCard';
import useStyles from './styles';
// import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function Reviews({ inRef }) {
    const classes = useStyles()
    let reviews = [
        { 
            content: `I would like to say thank you for everything. The art is great and your service is so wonderful. May Allah bless your work and bless your efforts. I will definitely come back and buy something again at some time. It’s got a special place above my work place so I can think of Mekka and Allah all the time!`,
            reviewer: 'Abdo'
        },
        { 
            content: `This canvas fit perfectly with the theme of the room. I was more than happy with the beautifully written calligraphy and the stunning glossy finish. May Allah put barakah in your business and may you flourish in every aspect.
            Thanks for this amazing piece!`,
            reviewer: 'Sadya. K'
        },
        { 
            content: ` I purchased this beautiful print that was taken from her original painting of this piece she titled,
            "From The River to the Sea". It's part of her "The Three Harams* Collection. I've been wanting this piece since she shared it last year and was hoping to purchase the full collection. I started with this one. The meaning behind this art piece holds my heart.`,
            reviewer: 'Haneen.O'
        },
        { 
            content: `The paintings came out beautiful; much better than what I had envisioned. Thank You!`,
            reviewer: 'Samina'
        },

    ]
    return (
        <Box className={classes.reviewsContainer} ref={inRef}>
            <Box className={classes.carouselContainer}>
                <Slider
                    autoplay={true}
                    pauseOnHover={false}
                    useTransform={false}
                    // width='100%'
                    // showArrows={false}
                    // showStatus={false}
                    // showThumbs={false}
                    // showIndicators={false}
                    // useKeyboardArrows
                    // autoPlay={true}
                    // interval={3000}
                    // transitionTime={1000}
                    // infiniteLoop
                    // swipeable={true}
                >
                    {
                        reviews.map((review, idx) => (
                            <ProductReviewCard content={review.content} reviewer={review.reviewer} />
                        ))
                    }

                </Slider>
            </Box>
        </Box>
    )
}
