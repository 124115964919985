import { Box, CircularProgress, Grid, IconButton, Paper } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete'

export default function ImageElement({
   image,
   index,
   isFirstElement,
   isLastElement,
   handleChangeOrderUp,
   handleChangeOrderDown,
   handleDeleteImage,
   changeImageField,
}) {
   return (
      <Box my={2} width={285} marginY={'30px'}>
         <Paper>
            <Grid container direction="row" justify="center" spacing={2}>
               <Grid item container alignItems="center" justify="center" xs={9}>
                  {image.downloadURL ? (
                     <img
                        src={image.downloadURL}
                        alt={`Upload Preview ${index + 1}`}
                        style={{
                           // aspectRatio: "auto 4 / 3",
                           width: "200px",
                           height: '200px',
                           objectFit: "cover",
                           margin: '3px'
                        }}
                     />
                  ) : (
                     <Box p={2}>
                        <CircularProgress />
                     </Box>
                  )}
               </Grid>
               <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                  item
                  xs={2}
               >
                  <Grid item container alignItems="center" justify="center">
                     {!isFirstElement && (
                        <IconButton
                           aria-label="Image up"
                           onClick={() => handleChangeOrderUp(index)}
                        >
                           <KeyboardArrowUpIcon />
                        </IconButton>
                     )}
                  </Grid>
                  <Grid
                     item
                     container
                     alignItems="center"
                     justify="center"
                     xs={4}
                  >
                     <IconButton
                        aria-label="Delete Image"
                        onClick={() => handleDeleteImage(index)}
                     >
                        <DeleteIcon style={{ color: 'red' }}/>
                     </IconButton>
                  </Grid>
                  <Grid item container alignItems="center" justify="center">
                     {!isLastElement && (
                        <IconButton
                           aria-label="Image down"
                           onClick={() => handleChangeOrderDown(index)}
                        >
                           <KeyboardArrowDownIcon />
                        </IconButton>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Paper>
      </Box>
   );
}