import React from "react";

import { Box } from '@mui/system';
import { Grid } from "@mui/material";

import ImagesDropzone from "./ImagesDropZone";
import ImageElement from "./ImageElement";

export default function MultipleImageUpload({ imageList, setImageList, limit = 11 }) {

   const changeImageField = (index, parameter, value) => {
      const newArray = [...imageList];
      newArray[index][parameter] = value;
      setImageList(newArray);
   };

   const handleChangeOrderUp = (index) => {
      // If first, ignore
      if (index !== 0) {
         const newArray = [...imageList];
         const intermediate = newArray[index - 1];
         newArray[index - 1] = newArray[index];
         newArray[index] = intermediate;
         setImageList(newArray);
      }
   };

   const handleChangeOrderDown = (index) => {
      // If last, ignore
      if (index < imageList.length - 1) {
         const newArray = [...imageList];
         const intermediate = newArray[index + 1];
         newArray[index + 1] = newArray[index];
         newArray[index] = intermediate;
         setImageList(newArray);
      }
   };

   const handleDeleteImage = (index) => {
         const newArray = [...imageList];
         newArray.splice(index, 1);
         setImageList(newArray);
   };

   return (
      <Grid container direction="column" alignItems="center" spacing={2}>
         <Box border={1} margin={4} padding={3} marginRight={'15px'}>
            <Grid
               item
               container
               direction="column"
               alignItems="center"
               xs={12}
               spacing={1}
            >
               <Grid item container xs={12} justify="center">
                  <ImagesDropzone setImageList={setImageList} limit={limit} />
               </Grid>
            </Grid>
         </Box>
         {imageList.length > 0 && (
            <Box bgcolor="primary.light" p={4}>
               {imageList.map((image, index) => {
                  return (
                     <Grid item key={image.file.size + index}>
                        <ImageElement
                           image={image}
                           index={index}
                           isFirstElement={index === 0}
                           isLastElement={index === imageList.length - 1}
                           handleChangeOrderUp={handleChangeOrderUp}
                           handleChangeOrderDown={handleChangeOrderDown}
                           handleDeleteImage={handleDeleteImage}
                           changeImageField={changeImageField}
                        />
                     </Grid>
                  );
               })}
            </Box>
         )}
      </Grid>
   );
}