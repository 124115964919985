import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  mainBody: {
    margin: '0 60px',
    marginBottom: '30px'
  },

  '@media (max-width:780px)': {
    mainBody: {
      margin: '0 10px'
    },
  }
}));
