import { makeStyles } from '@mui/styles';
// import image3 from '../../assets/images/image6.jpeg'

export default makeStyles(() => ({
    '@font-face': {
        fontFamily: 'Maharlika',
        src: 'url(../../assets/fonts/Maharlika.ttf)'
    },
    byName: {
        fontFamily: 'Maharlika',
        fontSize: '16pt'
    },

    cardSize: {
        width: '100px',
        height: '500px',
        marginLeft: '20px',
        marginRight: '20px'
    },
    '@media (max-width:600px)': {
        cardSize: {
          width: '100px',
        },
    }
    
}));
