import React, { useContext } from 'react'
import { LinearProgress } from '@mui/material'
import { AuthContext } from '../Providers/AuthProvider/AuthProvider'
import Login from '../ConsoleComponents/Login/Login'

const withAuthorization = (Component) => {
  return function WithAuthorization(props) {
    const { currentUser } = useContext(AuthContext);

    if (currentUser === 'loading') {
      return <LinearProgress />;
    }

    if (currentUser === null) {
      return <Login />;
    }

    return <Component {...props} />;
  };
};

export default withAuthorization;