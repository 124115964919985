import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

const NotFound = () => {
  const sxStyles = {
    notFoundContainer: {
      textAlign: 'center',
      padding: '5rem',
    },
    notFoundHeading: {
      fontSize: '3rem',
      marginBottom: '1rem',
      color: '#ff4f4f',
    },
    notFoundText: {
      fontSize: '1.2rem',
      color: '#555',
      marginBottom: '2rem',
    },
    backToHomeLink: {
      textDecoration: 'none',
    },
  };

  return (
    <div style={sxStyles.notFoundContainer}>
      <Typography variant="h1" sx={sxStyles.notFoundHeading}>
        404 - Page Not Found
      </Typography>
      <Typography sx={sxStyles.notFoundText}>
        The page you are looking for does not exist.
      </Typography>
      <Link to="/" sx={sxStyles.backToHomeLink}>
        <Button variant="contained" color="primary">
          Back to Home
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
