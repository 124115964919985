// This file contains the product type definitions and mappings
export const productTypes = [
  {
    value: 'originals',
    label: 'Original'
  },
  {
    value: 'prints',
    label: 'Print',
  },
  {
    value: 'journals',
    label: 'Journal'
  }
  // Add more product types here
  // All new products need to have rules also updated in firebase.
  // x_shipping_sizes and x where x is value of new product.
  // checkout function must be updated
  // new path in react router made for it with a related page for the new product
];

export const typeToName = productTypes.reduce((obj, type) => {
  obj[type.value] = type.label;
  return obj;
}, {});
