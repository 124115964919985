import React from 'react';
import LinearProgress from '@mui/material/LinearProgress'

const withLoading = (WrappedComponent) => {
  return function WithLoading({ loading, ...props }) {
    return (
      <div>
        {loading && <LinearProgress />}
        <WrappedComponent loading={loading} {...props} />
      </div>
    );
  };
};

export default withLoading;