import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useStyles from './styles';
// import popularImage from '../../assets/images/popularImage.jpeg' 
import { Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { SkeletonLoadImage } from '../SkeletonLoadImage/SkeletonLoadImage';


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#9A7B4F"),
  backgroundColor: "#9A7B4F",
  '&:hover': {
    backgroundColor: "#856a44",
  },
}));


export default function ProductCard({ image, price, title, productLink }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card>
      <SkeletonLoadImage
        src={`https://cdn.artbykynaat.com/${image}`}
        alt={title}
        // width={/* specify the desired width */}
        className={classes.media}
      />
      <CardContent>
        <Typography  fontSize={'16pt'}  component="div" color={'#705945'} textAlign={'center'} sx={{ minHeight: '57px', marginBottom: '15px' }}>
          {title.toUpperCase()}
        </Typography>
        <Divider />
        <Typography marginTop={1} variant="body1" color={'#705945'} textAlign={'center'}>
          From £{price}
        </Typography>
      </CardContent>
      <CardActions>
        <ColorButton 
          fullWidth 
          onClick={() => {history.push(productLink)}}
        > 
        View Details
        </ColorButton>
        {/* <Button 
          fullWidth 
          sx={{
            borderRadius: '0px',
            bgcolor: '#444444',
            color: 'white',
            ':hover': {
              outline: '1px black solid',
              bgcolor: 'white',
              color: 'black'
            }
          }}
        > Add To Cart </Button> */}
      </CardActions>
    </Card>
  )
}
