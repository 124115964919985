import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useStyles from '../CustomerNavbar/styles';
import { Box, Divider, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InfoIcon from '@mui/icons-material/Info';
import ReviewsIcon from '@mui/icons-material/Reviews';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import CreateIcon from '@mui/icons-material/Create';
// import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useHistory } from 'react-router-dom';

export default function Sidebar ({ aboutRef, reviewRef }) {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  const scrollToRef = (ref) => {
    // while (!ref) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      // console.log(ref)
    // }
    // ref.current.scrollIntoView({ behavior: 'smooth' });
    // console.log(ref)
  };

  const ListItems = {
    'Home' : { forward: () => history.push('/'), Icon: <HomeIcon /> },
    'About' : { forward: async () => {
      await history.push('/'); 
      scrollToRef(aboutRef)
    }, Icon: <InfoIcon />},
    'Products' : { 
      forward1: () => history.push('/products/originals'), 
      forward2: () => history.push('/products/prints'),
      forward3: () => history.push('/products/journals'),
      Icon: <StorefrontIcon /> 
    },
    'Custom Order': { forward: () => history.push('/order'), Icon: <CreateIcon />},
    'Reviews' : { forward: async () => {
      await history.push('/');
      scrollToRef(reviewRef)
    }, Icon: <ReviewsIcon />},
    'Contact Us': { forward: () => history.push('/contact'), Icon: <MailIcon />},
  }

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      sx={{width: '220px', height: '100%', background: ''}}
    >
      <List sx={{ marginTop: '60px' }}>
        {Object.keys(ListItems).map((text) => (
          text !== "Products" ?
          <ListItem key={text} sx={{ 'cursor': 'pointer' }} onClick={ListItems[text].forward}>
            <ListItemIcon>
              {ListItems[text].Icon}
            </ListItemIcon>
            <ListItemText primary={text} />
            <Divider /> 
          </ListItem> 
          :
          <span key={text}>
            <ListItem onClick={ListItems[text].forward}>
              <ListItemIcon>
                {ListItems[text].Icon}
              </ListItemIcon>
              <ListItemText primary={text} />
              <Divider /> 
            </ListItem>
            <ListItem sx={{ 'cursor': 'pointer', 'height': '30px', ml: '10px' }} onClick={ListItems[text].forward1}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={"Originals"} />
              <Divider /> 
            </ListItem>
            <ListItem sx={{ 'cursor': 'pointer', 'height': '30px', ml: '10px' }} onClick={ListItems[text].forward2}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={"Prints"} />
              <Divider /> 
            </ListItem>
            <ListItem sx={{ 'cursor': 'pointer', 'height': '30px', ml: '10px' }} onClick={ListItems[text].forward3}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={"Journals"} />
              <Divider /> 
            </ListItem>
          </span>
        ))}
      </List>

    </Box>
  );

  return (
    <div>
      <Box onClick={toggleDrawer(!isOpen)} className={classes.openSideBar}>
        {
          isOpen ?  <CloseIcon fontSize="largest"/> : <MenuIcon fontSize="largest"/>
        }
      </Box>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};
