import React, { useEffect, useState } from 'react';
import { useReward } from 'react-rewards';
import { Typography, Button, Paper, Box, TableBody, TableCell, Accordion, AccordionSummary, Table, AccordionDetails } from '@mui/material';
import { SkeletonLoadImage } from '../Components/SkeletonLoadImage/SkeletonLoadImage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Success = ({ removeAllFromCartNum }) => {
  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start', // Center horizontally, align to the top
    padding: '16px',
    marginLeft: '10px',
  };

  const paperStyles = {
    padding: '32px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    marginBottom: '16px',
    maxWidth: '700px'
  };
  const { reward, isAnimating } = useReward('rewardId', 'confetti', { 
    lifetime: 400, 
    zIndex: 9999
  });
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  
  let [cartItems, setCartItems] = useState([])

  useEffect(() => {
    // Retrieve user name and email from localStorage
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    const items = localStorage.getItem("items")
    const successItems = localStorage.getItem("successItems")

    if (successItems) {
      // persisting the successItems (until the cart starts getting used again... check addToCartNum)
      setCartItems(JSON.parse(successItems).map(v => ({...v, expanded: false})))
    }
    else if (items) {
      // transfer it into successItems.
      setCartItems(JSON.parse(items).map(v => ({...v, expanded: false})))
      localStorage.setItem("successItems", items)
      localStorage.removeItem("items")
      removeAllFromCartNum()
    }
      
    setUserName(storedName);
    setUserEmail(storedEmail);

    // confetti
    reward()

  }, []);

  const handleExpand = (idx) => {
    let tempItems = cartItems
    tempItems = tempItems.map((item, index) => {
      if (idx === index) {
        return {...item, expanded: !item.expanded}
      }
      return item
    }) 

    setCartItems(tempItems)
  }

  const handleContinueShopping = () => {
    // Handle the "Continue Shopping" button click
  };

  return (
    <div style={containerStyles}>
      <Paper style={paperStyles}>
        <span id="rewardId" />
        <Typography variant="h4" color="primary">
          Thank you, {userName}!
        </Typography>
        <Typography variant="body1">
          Your order has been successfully processed. We will be contacting you on tracking details shortly at {userEmail} 🙂.
        </Typography>
      </Paper>
      <Box>
        Order Items:
        {/* <Button
          style={continueShoppingButtonStyles}
          variant="contained"
          onClick={handleContinueShopping}
        >
          Continue Shopping
        </Button> */}
        <Table sx={{ minWidth: '320px' }} aria-label="simple table">

        {
          cartItems.map((item, idx) => (
            <TableBody key={idx}>
              <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {/* Clicking the photo or product name takes you to the product page */}
                    <Box mr={'20px'}>
                      <SkeletonLoadImage 
                        height={'80px'}
                        width={'80px'}
                        src={`https://cdn.artbykynaat.com/${item.image}`} 
                      />
                    </Box>
                    <Box width={'100%'}>
                      <b style={{display: 'block', fontSize: '20px', marginBottom: '-6px'}}>
                        {item.productName}
                      </b>
                      <Box sx={{ color: 'rgb(153, 153, 153)' }}>
                        {item.size}
                      </Box>
                      <Box sx={{
                          marginTop: '5px',
                          marginRight: '10px',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          backgroundColor: `${item.colour ? item.colour : '' }`,
                          position: 'relative',
                          boxShadow:`${item.colour ? '0 0 0 0.1pt black' : '' }`,
                          ':hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                      {
                        item.customise &&
                        <Accordion sx={{ marginTop: '15px', width: '100%'}} expanded={item.expanded} onChange={() => handleExpand(idx)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                              <Typography sx={{ width: '33%', flexShrink: 0 }}>Customisation</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography sx={{}}>
                                {item.customise}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                      }
                    </Box>
                  </Box>
                      
                      
                  </TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'top' }}>
                  </TableCell>
                </TableBody>
              ))
            }
          </Table>
      </Box>
    </div>
  );
};

export default Success;
