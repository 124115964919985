import { makeStyles } from '@mui/styles';
import image3 from '../../assets/images/image6.jpeg'

export default makeStyles(() => ({
    reviewsContainer: {
        minHeight: 'calc(100vh - 64px)',
        backgroundImage: `url(${image3})`,
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    carouselContainer: {
        width: '500px',
    },
    '@media (max-width:600px)': {
        carouselContainer: {
          width: '310px'
        },
    }
}));
