import React, { useCallback, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import useStyles from './styles';
import { useHistory } from 'react-router';
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";

// inspiration for design drawn from https://mui.com/getting-started/templates/sign-in/
export default function Login () {
  const history = useHistory()
  const classes = useStyles()
  const [isDisabled, setIsDisabled] = useState(false)
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        setIsDisabled(true)
        await signInWithEmailAndPassword(getAuth(), email.value, password.value);

      } catch (error) {
        // in the event that they input false credentials, allow them to re-attempt
        setIsDisabled(false)
      }
  }, []);

  return (
    <Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={classes.loginContent}>
          <Avatar sx={{ m: 1, bgcolor: '#008eb7', mt: 10 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">
            Log in
          </Typography>
          <Box component="form" onSubmit={handleLogin} className={classes.form}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
            />
            <Box className={classes.submitButton}>
              <Button
                sx={{
                  bgcolor: '#008eb7',
                  ':hover': {
                    bgcolor: '#008eb7',
                  }
                }}
                type="submit"
                fullWidth
                variant="contained"
                data-test-target='logInButton'
                disabled={isDisabled}
              >
                Log In
              </Button>
            </Box>
            <Typography variant="body2" align='center'>
              {"Forgot your credentials? "}
              <Link
                variant="body2"
                display='inline'
                data-test-target='registerLink'
                onClick={() => history.push('/contact')}
                className={classes.registerLink}
              >
                Contact Us
              </Link>
              , its free!
            </Typography>
          </Box>
        </Box>
        {/* <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
          {'Copyright © '}
          <Link color="inherit" href="/">
            SShopsy
          </Link>{' '}
          2021
        </Typography> */}
      </Container>
    </Box>
  );
}
