/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import useStyles from './styles';
import { Button } from '@mui/material';
import { getAuth, signOut } from '@firebase/auth';
import { AuthContext } from '../../Providers/AuthProvider/AuthProvider';
import { useHistory } from 'react-router';

export default function ConsoleNavbar () {
  const classes = useStyles()
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  
  return (
    <Box>  
      <Box className={classes.navbarContainer}>
        <Box className={classes.logoText} onClick={() => {history.push('/console')}}>
          ARTBYKYNAAT
        </Box>
        <Box className={classes.signOutButton}>
          {
            currentUser !== null && currentUser !== 'loading' &&
            <Button
              sx={{ color: 'white', margin: 0 }} 
              variant='contained' 
              onClick={() => { 
                  signOut(getAuth())
                  history.push('/console')
                }
              }
            >
              Sign Out
            </Button>
          }
        </Box>
      </Box>
      {/* to compensate for the space that the *FIXED* navbar takes up */}
      <Box className={classes.emptySpace}>
      </Box>
    </Box>
  )
}
