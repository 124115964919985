/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CreateIcon from '@mui/icons-material/Create';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Checkbox, CircularProgress, Divider, FormControlLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ColorPicker, useColor } from "react-color-palette";
import { CirclePicker } from "react-color"
import "react-color-palette/lib/css/styles.css";
import { doc, getDoc, setDoc, getFirestore, collection, getDocs } from "firebase/firestore";
import { useHistory } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import MultipleImageUpload from '../MultipleImageUpload/MultipleImageUpload';
import { NumericFormatCustom } from '../../Components/NumericFormatCustom/NumericFormatCustom';
// import Sharp from 'sharp';
import { v4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { useSnackbar } from '../../Providers/SnackbarProvider/SnackbarProvider';
import { productTypes } from '../../productTypes';
import ArchiveIcon from '@mui/icons-material/Archive';
// import useStyles from './styles';

const useStyles = makeStyles((theme) => ({
  mainBody: {
    margin: '0 60px',
  },
  root: {
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'space-around'
  },
  input: {
    display: 'none',
  },
  img: {
    objectFit: 'cover',
    width: '500px',
    aspectRatio: '4/3'
  },
  fadeOut: {
    opacity: "0",
    transition: "width 0.25s 0.25s, height 0.25s 0.25s, opacity 0.25s"
  },
  fadeIn: {
    opacity:"1",
    transition: "width 0.25s, height 0.25s, opacity 0.25s 0.25s"
  },
  '@media (max-width:780px)': {
    mainBody: {
      margin: '0 10px'
    },
    img: {
      objectFit: 'cover',
      width: '500px',
      aspectRatio: '16/9'
    },
  }
}));
export default function AddProduct () {
  const history = useHistory()
  const API = '/.netlify/functions'
  const db = getFirestore()
  const snackbar = useSnackbar()
  
  
  const [disabled, setDisabled] = useState(false)

  // params for the new product

  const [productName, setProductName] = useState('');
  const [itemCount, setItemCount] = useState('')
  const [productDesc, setProductDesc] = useState('');
  const [customisable, setCustomisable] = useState(false)
  /////////////////////// colour picker functionality below ////////////////////
  const [color, setColor] = useColor("hex", "#000000");
  const [currColour, setCurrColour] = useState('')
  const [colours, setColours] = useState([])

  useEffect(() => {
    setCurrColour(color.hex)
  }, [color])

  const confirmColour = () => {
    if (currColour !== null && !colours.includes(currColour)) {
      setColours([...colours, currColour])
    }
  }

  const clearColour = () => {
    setColours([])
  }
  // https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
  const inv = (hexcolor) => {
    if (!hexcolor) {
      return '#ffffff'
    }

    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
  }
  //////////////////////////////////////////////////////////////////////////////

  ////////////////////////////// product types /////////////////////////////////
  
  const [productType, setProductType] = useState(productTypes[0].value);
  //////////////////////////////////////////////////////////////////////////////

  ////////////////////////////// sizes of products /////////////////////////////
  // Make it so that this is fetched from the data base.
  // fill in checked and price
  // need a loader whilst fetching is occuring
  const [sizes, setSizes] = useState({
    // e.g.
    // 'A0': { checked: false, price: null },
    // 'A1': { checked: false, price: null },
    // 'A2': { checked: false, price: null },
    // 'A3': { checked: false, price: null },
    // 'A4': { checked: false, price: null },
    // 'A5': { checked: false, price: null },
    // '101x101': { checked: false, price: null },
    // '90x90': { checked: false, price: null },
    // '81x81': { checked: false, price: null },
    // '71x71': { checked: false, price: null },
    // '60x60': { checked: false, price: null },
    // '50x50': { checked: false, price: null },
    // '50x20': { checked: false, price: null },
    // '40x40': { checked: false, price: null },
    // '30x30': { checked: false, price: null },
    // '20x20': { checked: false, price: null },
  })
  const [sizesLoading, setSizesLoading] = useState(true)

  useEffect(() => {
    const fun = async () => {
      setSizesLoading(true)
      let x = await getDocs(collection(db, `${productType}_shipping_sizes`))
      let docs = {}

      x.forEach((doc) => {
        docs[doc.id] = { checked: false, price: '' }
      })
      setSizes(docs)
      setSizesLoading(false)
    }
    fun()
  }, [productType])

  const handleSizeSelect = (event) => {
    setSizes({
      ...sizes,
      [event.target.name]: { checked: event.target.checked, price: '' },
    });
  };

  const handleSizePriceChange = (key, price) => {
    setSizes({
      ...sizes,
      [key]: { checked: sizes[key].checked, price: price },
    })
    console.log({
      ...sizes,
      [key]: { checked: sizes[key].checked, price: price },
    })
  }

  //////////////////////////////////////////////////////////////////////////////

  

  const extractSizes = () => {
    const res = []
    Object.keys(sizes).forEach((size) => {
      if (sizes[size].checked) {
        res.push({ size, price: parseFloat(sizes[size].price).toFixed(2), active: true })
      }
    })

    return res
  }

  const [imageList, setImageList] = useState([]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (!validate()) {
      return
    }

    snackbar.showSnackbar({
      severity: 'info',
      message: 'Creating your product',
      autoHideDuration: 2000,
    })
    setDisabled(true)
    console.log(productDesc)
    const docSnapshot = await getDoc(doc(db, `${productType}`, productName.trim().toLowerCase().replace(/ /g, "-")))
    if (docSnapshot.exists()) {
      return
    }

    const { key } = (await getDoc(doc(db, 'keys/createProductKey'))).data()
    const { bunnyCDNKey } = (await getDoc(doc(db, 'keys/bunnyKey'))).data()

    const imagePaths = imageList.map((_) =>  `product_images/${productType}/${productName.trim().toLowerCase().replace(/ /g, "-")}/${v4()}.jpeg`)

    const thumbnail = `product_images/${productType}/${productName.trim().toLowerCase().replace(/ /g, "-")}/thumbnail/${imagePaths[0].split('/').pop()}`

    imageList.map(async (image, idx) => {
      if (idx === 0) {
        // Compress the image
        const options = {
          maxSizeMB: 0.6,
          fileType: 'image/jpeg',
          initialQuality: 0.9,
          maxWidthOrHeight: 700,
          useWebWorker: true,
        }

        const compressedFile = await imageCompression(image.file, options);

        const bunnyOptions = {
          method: 'PUT',
          headers: {
            AccessKey: bunnyCDNKey,
            'content-type': 'application/octet-stream'
          },
          body: compressedFile
        };
        const bunnyUrl = `https://storage.bunnycdn.com/artbykynaat/${thumbnail}`;
    
        fetch(bunnyUrl, bunnyOptions)
          .then(res => res.json())
          .then(json => console.log(json))
      }
      
      // Compress the image
      const options = {
        maxSizeMB: 1.5,
        fileType: 'image/jpeg',
        initialQuality: 0.9,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      }
      
      const compressedFile = await imageCompression(image.file, options);

      const bunnyOptions = {
        method: 'PUT',
        headers: {
          AccessKey: bunnyCDNKey,
          'content-type': 'application/octet-stream'
        },
        body: compressedFile
      };
      const bunnyUrl = `https://storage.bunnycdn.com/artbykynaat/${imagePaths[idx]}`;
  
      fetch(bunnyUrl, bunnyOptions)
        .then(res => res.json())
        .then(json => console.log(json))
    })

    const productSizesPrices = extractSizes()

    const res = await fetch(`${API}/createProduct`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        thumbnail: imagePaths[0],
        sizes: productSizesPrices,
        name: productName, 
        description: productDesc,
        key: key
      })
    })

    const { product, prices } = await res.json()

    const newProduct = {
      id: product.id,
      name: productName.trim(),
      prices: prices,
      description: productDesc.trim(),
      colours,
      thumbnail: thumbnail,
      images: imagePaths,
      customisable: customisable,
      itemCount,
      active: true // !archived 
    }
    
    await setDoc(doc(db, `${productType}`, productName.trim().toLowerCase().replace(/ /g, "-")), newProduct)
    
    setDisabled(false)
    history.push('/console/products')
    
    snackbar.showSnackbar({
      severity: 'success',
      message: `Successfully created ${productName}`,
      autoHideDuration: 4000,
    })
  }


  const validate = () => {
    let message = ""
    if (productName.trim() === '') {
      message = "Please enter a product name"
    }
    else if (productDesc.trim() === '') {
      message = "Please enter a product description"
    }
    else if (imageList.length <= 0) {
      message = "Please provide atleast one image"
    }
    else if (!Object.values(sizes).some(size => size.checked)) {
      message = "Please select atleast one size"
    }
    else if (!Object.values(sizes).every(size => ((size.checked && size.price !== '') || (!size.checked)))) {
      message = "Please input a price for all sizes"
    }
    else if (!itemCount) {
      message = "Please give a count of items"
    }

    if (message) {
      snackbar.showSnackbar({
        severity: 'error',
        message,
        autoHideDuration: 5000,
      })
      return false
    }

    return true
  }


  const renderCheckboxes = (keys) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
      {keys.map((key, index) => (
        <Box sx={{ width: '100%', marginX: '10px' }}>
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                disabled={disabled}
                checked={sizes[key].checked}
                onChange={handleSizeSelect}
                name={key}
              />
            }
            label={key}
          />
        </Box>
      ))}
    </Box>
  );

  const classes = useStyles();

  return (
    <Box className={classes.mainBody}>
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Add New Product
      </Typography>
      {/* Product Type */}
      <TextField
        disabled={disabled}
        required
        name="productType"
        margin="dense"
        id="standard-select-product"
        select
        label="Product Type"
        fullWidth
        value={productType}
        onChange={e => setProductType(e.target.value)}
      >
        {productTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
      </TextField>
      
      {/* Name */}
      <TextField
        disabled={disabled}
        required
        name="productName"
        margin="dense"
        id="productName"
        autoComplete='off'
        label="Product Name"
        value={productName}
        onChange={e => setProductName(e.target.value)}
        fullWidth
      />

      <TextField 
        disabled={disabled}
        required
        name="itemCount"
        inputProps={{
          inputMode: 'numeric',
          pattern: '\d*', // Regular expression to allow only whole numbers
        }}
        value={itemCount}
        onChange={(event) => /^\d*$/.test(event.target.value) && setItemCount(event.target.value)}
        margin="dense"
        autoComplete='off'
        label="Item Count"
        fullWidth
      />

      {/* Description */}
      <TextField
        disabled={disabled}
        required
        margin="dense"
        multiline
        label="Description"
        value={productDesc}
        onChange={e => setProductDesc(e.target.value)}
        fullWidth
        minRows={3}
        sx={{
          whiteSpace: 'pre-line'
        }}
      />
      
      <Box>
        <Typography fontSize={17} sx={{ color: 'gray' }} marginTop={1} gutterBottom>
          Customisable
        </Typography>
        <Switch
          size="large"
          checked={customisable}
          onChange={(event, value) => setCustomisable(!customisable)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Box>

      {/* Colour pickers. */}
      <Box>
        <Typography fontSize={17} sx={{ color: 'gray' }} marginTop={1} gutterBottom>
          Product Colours 
        </Typography>
        
        <Box sx={{  display: 'flex', justifyContent: 'space-around', marginBottom: '10px' }}>
            <ColorPicker width={350} height={200} color={color} onChange={setColor} hideHSV hideRGB dark />
        </Box>

        <Box sx={{  display: 'flex', justifyContent: 'space-around' }}>
          <Button
            disabled={disabled}
            variant='contained'
            style={{ backgroundColor: `${currColour}` }}
            sx={{ marginBottom: '15px', height: '50px', width:'350px', color: `${inv(currColour)}` }}
            onClick={confirmColour}
          >
            Add Colour
          </Button>
        </Box>

        <Box sx={{
          marginBottom: '20px',
          marginLeft: '10px',
          '* * *': {
            boxShadow: '0 0 0 0.4pt black',
            // boxShadow: '1px 1px 1px 1px black',
            borderRadius: '28px',
            // outline: 'none'
          }
        }}>
          <CirclePicker colors={colours} />
        </Box>
      
        {
          !!colours.length &&
          <Button 
            onClick={clearColour}
            color='error'
            variant="contained"
            startIcon={<DeleteIcon />}  
          >
            Delete Colours
          </Button>
        }
      </Box>
      
      <Typography fontSize={17} sx={{ color: 'gray' }} marginTop={1} gutterBottom>
        Product Images  
      </Typography> 
      <MultipleImageUpload imageList={imageList} setImageList={setImageList}/>

      {/* Selector for sizes */}
      <Box>
        <Typography fontSize={17} sx={{ color: 'gray' }} marginTop={1} gutterBottom>
          Sizes 
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {Object.keys(sizes).length ? (
            <>
                {renderCheckboxes(Object.keys(sizes).slice(0, Math.ceil(Object.keys(sizes).length / 2)))}
                {renderCheckboxes(Object.keys(sizes).slice(Math.ceil(Object.keys(sizes).length / 2)))}
            </>
          ) : sizesLoading ? (
            <CircularProgress />
          ) : (
            `No sizes available for ${productType}`
          )}
        </Box>
      </Box>
      
      {/* Prices per size */}
      <Box>
        <Typography fontSize={17} sx={{ color: 'gray' }} marginTop={1} gutterBottom>
          Prices
        </Typography>

        {
          <Box sx={{marginTop: 1}}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 550 }} aria-label="prices table">
                <TableHead>
                  <TableRow>
                    <TableCell>Size</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(sizes).map((key, index) => (
                    <TableRow key={index} className={!sizes[key].checked ? classes.fadeOut : classes.fadeIn}>
                      {sizes[key].checked && (
                        <TableCell>
                            <Typography fontSize={18}>
                              {`${key}`}
                            </Typography>
                            
                        </TableCell>
                      )}
                      {
                        sizes[key].checked && (
                          <TableCell align="left">
                            <TextField 
                              sx={{ width: '200px' }}
                              disabled={!sizes[key].checked || disabled}
                              name="price"
                              required
                              label="Price"
                              variant="outlined"
                              value={sizes[key].price}
                              onChange={(event) => { handleSizePriceChange(key, event) }}
                              // onChange={(event) => { console.log(event) }}
                              InputProps={{
                                inputComponent: NumericFormatCustom
                              }}
                            />
                          </TableCell>
                        )
                      }
                      {
                        sizes[key].checked && (
                          <TableCell>
                            <Button 
                              fullWidth
                              startIcon={<ArchiveIcon />}
                              disabled={true}
                              variant='contained'
                              sx={{
                                bgcolor: '#444444',
                                color: 'white'
                              }}
                            > 
                              Archive
                            </Button>
                          </TableCell>

                        )
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {
              !Object.keys(sizes).filter(size => sizes[size].checked).length && 
                <Typography align='center' variant='h6'>
                  No sizes selected
                </Typography>
            }
          </Box>
        }
      </Box>
      
      {/* Submit button */}
      <Button 
        disabled={disabled}
        onClick={handleSubmit} 
        fullWidth 
        color="primary" 
        variant="contained" 
        startIcon={<CreateIcon />}
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
          Create
      </Button>
    </Box>
  );
}

