import { Box } from '@mui/material'
import React from 'react'
import CustomOrderForm from '../Components/CustomOrderForm/CustomOrderForm'

const CustomOrderPage = () => {
  return (
    <Box>
      <CustomOrderForm />
    </Box>
  )
}

export default CustomOrderPage