import React, { useState, useRef } from 'react'
import useStyles from './styles';
import { Box, Button, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MultipleImageUpload from '../../ConsoleComponents/MultipleImageUpload/MultipleImageUpload';
import { useSnackbar } from '../../Providers/SnackbarProvider/SnackbarProvider';
import ColorButton from '../PrimaryButton/PrimaryButton';
import ReCAPTCHA from "react-google-recaptcha"
import emailjs from '@emailjs/browser';

export default function CustomOrderForm() {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [dimensions, setDimensions] = useState('')
  const [colours, setColours] = useState('')
  const [design, setDesign] = useState('')
  const [disabled, setDisabled] = useState(false);
  const captchaRef = useRef(null)

  const snackbar = useSnackbar()

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    
    const token = captchaRef.current.getValue();
    
    setDisabled(true);

    // Replace 'service_****' and 'template_*****' with your actual Email.js service and template IDs
    const emailParams = {
      name,
      email,
      size: dimensions,
      colours,
      design,
      'g-recaptcha-response': token, // Include the reCAPTCHA token
    };
  
    // Use Email.js to send the email
    emailjs
      .send('artbykynaat', 'custom_order', emailParams, '6LCGw0Fr2u0NAIhP7')
      .then(
        (response) => {
          snackbar.showSnackbar({
            severity: 'success',
            message:
              'Your custom order has been received! We will be in contact with you soon 🙂',
            autoHideDuration: 4000,
          });
        },
        (error) => {
          snackbar.showSnackbar({
            severity: 'error',
            message: 'An unexpected error occurred. Please contact me directly at artbykynaat@gmail.com',
            autoHideDuration: 10000,
          })
        }
      );
  };
  
  const validate = () => {
    let message = ''
    if (name === '') {
      message = 'Please enter your name'
    }
    else if (email === '') {
      message = 'Please enter your email'
    }
    else if (dimensions === '') {
      message = 'Please enter dimensions'
    }
    else if (colours === '') {
      message = 'Please enter some colours'
    }
    else if (design === '') {
      message = 'Please outline a design'
    }
    else if (!captchaRef.current.getValue()) {
      message = 'Please complete the captcha'
    }
    if (message) {
      snackbar.showSnackbar({
        severity: 'error',
        message,
        autoHideDuration: 4000,
      })

      return false
    }

    return true
  }

  return (
    <Box className={classes.mainBody}>
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Custom Order
      </Typography>

      <TextField
        required
        name="Name"
        margin="dense"
        id="name"
        autoComplete='off'
        label="Name"
        value={name}
        onChange={e => setName(e.target.value)}
        fullWidth
      />

      <TextField
        required
        name="Email"
        margin="dense"
        id="email"
        autoComplete='off'
        label="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        fullWidth
      />

      <TextField
        required
        name="Dimensions"
        margin="dense"
        id="dimensions"
        autoComplete='off'
        label="Dimensions"
        value={dimensions}
        onChange={e => setDimensions(e.target.value)}
        fullWidth
      />

      <TextField
        required
        name="Colour Palette"
        margin="dense"
        id="colours"
        autoComplete='off'
        label="Colour Palette"
        value={colours}
        onChange={e => setColours(e.target.value)}
        placeholder='Black, Blue, White, ...'
        fullWidth
      />

      <TextField
        required
        margin="dense"
        multiline
        id="design"
        label="Design"
        rows={3}
        placeholder='Calligraphy Details, References, etc.'
        value={design}
        onChange={e => setDesign(e.target.value)}
        fullWidth
        sx={{
          whiteSpace: 'pre-line'
        }}
      />

      {/* <Typography variant='h6' sx={{ marginTop: '10px' }}>
        Upload Attachments (optional)
      </Typography>
      <MultipleImageUpload imageList={imageList} setImageList={setImageList} limit={3} /> */}
      {/* <Typography variant='h6' sx={{ marginTop: '10px' }}>
        
      </Typography> */}
      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
        <ReCAPTCHA 
          sitekey={'6Lc4-ecnAAAAAHxFQozrq6pgpBWxbhG2hhPMnTPT'} 
          ref={captchaRef}
        />
      </Box>

      
      {/* Submit button */}
      <ColorButton
        disabled={disabled}
        onClick={handleSubmit} 
        fullWidth 
        color="primary" 
        variant="contained" 
        endIcon={<SendIcon />}
        sx={{
          marginTop: '20px',
          marginBottom: '20px'
        }}
      >
        Fetch a Quote
      </ColorButton>
    </Box>
  )
}
