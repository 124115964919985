import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useStyles from './styles';
// import popularImage from '../../assets/images/popularImage.jpeg' 
import { Divider } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useHistory } from 'react-router'
import { SkeletonLoadImage } from '../../Components/SkeletonLoadImage/SkeletonLoadImage';


export default function ConsoleProductCard({ productName, productType, image, title, active, docRef }) {
  const classes = useStyles();
  const db = getFirestore()
  const documentRef = doc(db, docRef);
  const history = useHistory()

  const [localActive, setLocalActive] = useState(active)
  const [disabledBt, setDisabledBt] = useState(false)

  const archiveProduct = async () => {
    setDisabledBt(true)
    await updateDoc(documentRef, { active: false });
    setLocalActive(false)
    setDisabledBt(false)
  }

  const unarchiveProduct = async () => {
    setDisabledBt(true)
    await updateDoc(documentRef, { active: true });
    setLocalActive(true)
    setDisabledBt(false)
  }
  

  return (
    <Card>
      {/* <CardMedia
        image={image}
        className={classes.media}
      /> */}
      <SkeletonLoadImage
        src={image}
        alt={title}
        // width={/* specify the desired width */}
        className={classes.media}
      />
      <CardContent>
        <Typography  
          fontSize={'14pt'}  
          component="div" 
          color={'#705945'} 
          textAlign={'center'} 
          sx={{ minHeight: '57px', marginBottom: '15px', cursor: 'pointer' }} 
          onClick={() => {history.push(`/products/${docRef}`)}}
        >
          {title}
        </Typography>
        <Divider />

      </CardContent>
      <CardActions>
        <Button 
          fullWidth 
          color="primary"
          variant='contained'
          startIcon={<EditIcon />}
          onClick={() => { history.push(`/console/products/edit/${productType}/${productName}`) }}
          sx={{
            borderRadius: '0px',
            ':hover': {
              outline: '1px black solid',
              bgcolor: 'white',
              color: 'black'
            },
            fontSize: '11pt'

          }}
        > 
          {/* {if product is active then allow this to be enabled otherwise disable it} */}
          Edit
        </Button>
        {
          localActive ? 
          <Button 
          fullWidth 
          startIcon={<ArchiveIcon />}
          onClick={archiveProduct}
          disabled={disabledBt}
          sx={{
            borderRadius: '0px',
            bgcolor: '#444444',
            color: 'white',
            ':hover': {
              outline: '1px black solid',
              bgcolor: 'white',
              color: 'black'
            }
          }}
        > 
          Archive
        </Button>
        :
        <Button 
          fullWidth 
          startIcon={<UnarchiveIcon />}
          color={'success'}
          variant='contained'
          onClick={unarchiveProduct}
          disabled={disabledBt}
          sx={{
            borderRadius: '0px',
            ':hover': {
              outline: '1px black solid',
              bgcolor: 'white',
              color: 'black'
            }
          }}
        > 
          Unarchive
        </Button>
        }
      </CardActions>
    </Card>
  )
}
