import { Typography } from '@mui/material';
import { Box } from '@mui/system'
import React from 'react'
import useStyles from './styles';


export default function About ({ inRef }) {
  const classes = useStyles()

  return (
    <Box className={classes.textAreaCont} ref={inRef}>
      <Box className={classes.textArea}>
        <Typography variant='h5' textAlign='center' marginTop={3} letterSpacing={'5px'}>
          ABOUT
        </Typography>
        {/* <Divider variant='middle' className={classes.divider} /> */}
        <Typography variant='h6' textAlign='center' color={'#705945'} fontFamily={'"Century Gothic" sans-serif'} marginTop={2} marginBottom={5}>
          My discovery and journey in Islamic Art was a means by which I quenched an unfound emptiness.
          I fell in love with it immediately. 
          I started to experiment with various styles, mediums and colours to incorporate into my pieces, enabling me to communicate ideas grounded in religious roots. My aim is to create art which inspires others spiritually, mentally and emotionally. 
          I hope you find a piece which resonates with you and serves as an everlasting reminder.
        </Typography>

      </Box>
    </Box>
  )
}
