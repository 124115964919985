import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  registerLink: {
    cursor: 'pointer'
  },
  loginContent: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginTop: 1
  },
  submitButton: {
    marginTop: 30,
    marginBottom: 15
  }
}));
