import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification({ data }) {
  const { severity, message, autoHideDuration } = data;
  const [snackbarOpen, setSnackbarOpen] = useState(true); // Snackbar is initially open

  // useEffect(() => {
  //   // Automatically close the snackbar after the specified duration
  //   const timeout = setTimeout(() => {
  //     setSnackbarOpen(false);
  //   }, autoHideDuration || 6000);

  //   return () => clearTimeout(timeout);
  // }, [autoHideDuration]);
  useEffect(() => {
    setSnackbarOpen(true)
  }, [data])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={autoHideDuration || 6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
