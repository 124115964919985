import { Box } from '@mui/system'
import React from 'react'
import useStyles from './styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useHistory } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

export default function CustomerNavbar ({ cartNum, aboutRef, reviewRef }) {

  const history = useHistory()

  const classes = useStyles();
  return (
    <Box>  
      <Box className={classes.navbarContainer}>
        <Sidebar aboutRef={aboutRef} reviewRef={reviewRef}/>
        <Box sx={{cursor: 'pointer'}}className={classes.logoText} onClick={() => {history.push('/')}}>
          ARTBYKYNAAT
        </Box>
        <Box className={classes.cartIcon}>
          <Box sx={{ display: 'inline-block', position: 'relative' }} onClick={() => {history.push('/cart')}}>
            <ShoppingCartIcon fontSize="largest"/>
            <Box sx={{ 
              display: 'inline-block',
              position: 'absolute',
              top: '3px',
              right: '-5px',
              padding: '5px 10px',
              borderRadius: '50%',
              height: '10px',
              width: '0px',
              backgroundColor: 'red',
              color: 'white',
              fontSize: '0.7em',
            }}/>
          
            <Box sx={{
              display: 'inline-block',
              position: 'absolute',
              fontSize: '15px',
              top: '-18.5px',
              right: '2.5px',
              fontFamily: 'sans-serif'
            }}>
              {cartNum}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* to compensate for the space that the *FIXED* navbar takes up */}
      <Box className={classes.emptySpace}>
      </Box>
    </Box>
  )
}
