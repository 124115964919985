import { Box, Button, Divider, TextField, InputAdornment } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Carousel } from 'react-responsive-carousel';
import useStyles from './styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import EditIcon from '@mui/icons-material/Edit';
import { SkeletonLoadImage } from '../SkeletonLoadImage/SkeletonLoadImage';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSnackbar } from '../../Providers/SnackbarProvider/SnackbarProvider';
import ColorButton from '../PrimaryButton/PrimaryButton';
import { typeToName } from '../../productTypes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function Product({ addToCartNum, productType, productName }) {
  const [disabled, setDisabled] = useState(false)

  const [images, setImages] = useState([])
  const [product, setProduct] = useState({ description: '', name: ''})
  const [colours, setColours] = useState([])
  const [selectedColor, setSelectedColor] = useState(null);
  const [sizes, setSizes] = useState([])
  const [selectSized, setSelectedSize] = useState(null)
  const [selectSizedIdx, setSelectedSizeIdx] = useState(null)
  const [isCustomisable, setIsCustomisable] = useState(false)
  const [customisation, setCustomisation] = useState('')
  const componentRef = useRef()
  const { width } = useContainerDimensions(componentRef)

  const snackbar = useSnackbar();

  useEffect(() => {
    const fun = async () => {
      const db = getFirestore()
      const prod = await getDoc(doc(db, `${productType}/${productName}`))
      const productData = prod.data()
      setProduct(productData)
      setColours(productData.colours)
      let keys = [];
      for (var key in productData.prices) {
        if (productData.prices[key].active) {
          keys.push(key);
        }
      }
      setSizes(keys.sort(function(a,b){ 
        var x = productData.prices[a]["price"] > productData.prices[b]["price"] ? -1 : 1; 
        return x; 
      }))
      setImages(productData.images)
      setIsCustomisable(productData.customisable)

    }

    fun()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addToCart = async () => {
    setDisabled(true)
    // add item to local storage as:
    // productType/productName?size=${size}&colour=${colour}&customise=${msg}
    // first get existing items
    let items = JSON.parse(localStorage.getItem("items")) ? JSON.parse(localStorage.getItem("items")) : []
    items = [...items,
      {
        image: product.thumbnail,
        productName: product.name,
        itemCount: product.itemCount,
        productType: typeToName[productType],
        size: selectSized,
        colour: selectedColor,
        priceId: product.prices[selectSized]["id"],
        price: product.prices[selectSized]["price"],
        customise: customisation,
      }
    ]
    localStorage.setItem("items", JSON.stringify(items))
    addToCartNum()
    snackbar.showSnackbar({
      severity: 'success',
      message: `${product.name} has been added to your cart!`,
      autoHideDuration: 4000,
    });
    
    // Delay the enabling of the button by a second.
    await new Promise(resolve => setTimeout(resolve, 1000));
    setDisabled(false)
  }

  const handleColorSelection = (color) => {
    setSelectedColor(color)
  }

  // https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
  const inv = (hexcolor) => {
    if (!hexcolor) {
      return '#ffffff'
    }

    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 230) ? 'black' : 'white';
  }

  
  // 670 break point
  const classes = useStyles();
  return (
    <Box>
      {
        <Box>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-around', 
            margin: '20px',
            '@media (max-width:670px)': {
              flexDirection: 'column', 
            }
          }}>
            <Box 
              ref={componentRef}
              sx={{ 
                width: `${images.length > 0 ? '50%' : '100%'}`,
                marginBottom: '40px',
                '@media (max-width:670px)': {
                  width: '100%', 
                }
              }}>
              <Slider
                pauseOnHover={false}
                useTransform={false}
                dots={true}
                nextArrow={
                  <ArrowForwardIosIcon sx={{ 
                    color: 'black', 
                    right: '0px', 
                    ':hover': { color: 'black' } 
                  }}/>
                }
                prevArrow={
                  <ArrowBackIosIcon sx={{
                    zIndex: '999',
                    color: 'black', 
                    left: '7px',
                    ':hover': { color: 'black' } 
                  }}/>
                }
                >
              {
                (images.length > 0) ?
                  images.map((image, idx) => (
                    <div key={idx}>

                      <SkeletonLoadImage
                        src={`https://cdn.artbykynaat.com/${image}`} 
                        alt='carouselImg' 
                        // width={width} 
                        className={classes.carouselImg}
                      />

                    </div>
                  )) :
                  <Skeleton height={width} count={1}/>
              }
              </Slider>
            </Box>

            <Box sx={{ 
              width: '100%', 
              marginLeft: '20px', 
              '@media (max-width:670px)': {
                marginLeft: '0px',
                width: '100%', 
              }
            }}
            >
              {/* Product Type */}
              <Box sx={{ fontSize: '18pt', marginBottom: '0px', color: "#9A7B4F" }}>
                <b>{typeToName[productType]}</b>
              </Box>
              
              {/* Product Name */}
              <Box sx={{ fontSize: '26pt', marginBottom: '15px'}}>
                {product.name ? product.name : <Skeleton lines={1}/>}
              </Box>
              {/* Product Description */}
              <Box sx={{ fontSize: '16pt', marginBottom: '30px', color: "gray", whiteSpace: 'pre-line' }}>
                {product.description ? product.description : <Skeleton count={8} />}
              </Box>
              <Divider />

              { colours.length > 0 &&
                <Box>
                  {/* Colour picker */}
                  <Box sx={{ fontSize: '12pt', marginTop: '15px', marginBottom: '10px', color: "gray" }}>
                    <b>Colour</b>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {colours.map((color) => (
                      <Box key={color} onClick={() => handleColorSelection(color)}>
                        <Box sx={{
                          marginRight: '10px',
                          marginBottom: '10px',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          backgroundColor: color,
                          position: 'relative',
                          boxShadow: '0 0 0 0.1pt black',
                          ':hover': {
                            cursor: 'pointer'
                          }
                        }}> 
                          {selectedColor === color && <Box sx={{
                            position: "absolute",
                            backgroundColor: `${inv(color)}`,
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            top: '7.5px',
                            left: '7.5px',
                            // fontSize: "1.2em",
                            // paddingLeft: '7px',
                            // marginTop: '3px',
                            "&::before": {
                              // content: '"✔"',
                              width: '35px',
                              height: '15px',
                            }
                          }}> </Box>}
                        </Box>
                      </Box>
                        
                    ))}
                  </Box>
                </Box>
                }
                {
                  !!sizes.length && 
                  <Box>
                    {/* Size Picker */}
                    <Box sx={{ fontSize: '12pt', marginTop: '15px', marginBottom: '10px', color: "gray" }}>
                      <b>Size</b>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}>
                      {
                        sizes.length && sizes.map((size, idx) => (
                          <>
                            {
                              selectSizedIdx === idx ? 
                                <ColorButton variant='contained' sx={{ 
                                  bgcolor:'#9A7B4F',
                                  minWidth: '100px',
                                  marginRight: '5px',
                                  marginBottom: '10px',
                                  
                                }} onClick={() => {setSelectedSize(size); setSelectedSizeIdx(idx)}}>
                                  {size}
                                </ColorButton> : 
                                <ColorButton variant='contained' sx={{ 
                                  bgcolor:'white', 
                                  minWidth: '100px', 
                                  marginRight: '5px',
                                  marginBottom: '10px',
                                  border: '1px solid #9A7B4F',
                                  color: '#616161',
                                  ':hover': {
                                    bgcolor: '#9A7B4F',
                                    color: 'white'
                                  },
                                }} onClick={() => {setSelectedSize(size); setSelectedSizeIdx(idx)}}>
                                  {size}
                                </ColorButton>
                            }
                          </>
                        ))
                      }
                    </Box>
                    
                    {
                      isCustomisable && 
                      <Box>
                        <Box sx={{ fontSize: '12pt', marginTop: '15px', marginBottom: '10px', color: "gray" }}>
                          <b>Customise</b>
                        </Box>
                        <TextField 
                          placeholder="Enter your customisation preferences"
                          label="Customisation Preference" 
                          fullWidth 
                          multiline
                          value={customisation}
                          onChange={e => setCustomisation(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EditIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    }

                    <Box sx={{ fontSize: '12pt', marginTop: '15px', marginBottom: '10px', color: "gray" }}>
                      <b>Price</b>
                    </Box>
                    <Box>
                      {
                        !selectSized || (colours.length && !selectedColor) ? 
                          <b> Please select a size {`${colours.length ? 'and colour' : ''}`} </b> : 
                          <b> {`£${product.prices[`${selectSized}`]["price"]} (Excl. Shipping)`} </b>
                      }
                      
                    </Box>
                  </Box>
                }
              <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <ColorButton 
                  fullWidth
                  variant='contained' 
                  disabled={!selectSized || (colours.length && !selectedColor) || disabled}
                  startIcon={<AddShoppingCartIcon />}
                  onClick={() => {addToCart()}}
                  sx={{
                    bgcolor: 'black',
                    color: 'white',
                    // border: '1px white solid',
                    ':hover': {
                      bgcolor: 'white',
                      color: 'black',
                      border: '1px black solid'
                    },
                    fontSize: '11pt'
                  }}
                >
                  Add to Cart
                </ColorButton>
              </Box>
              
            </Box>
              
          </Box>
        </Box>
      }
    </Box>
  )
}


export const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};