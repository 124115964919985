import React from 'react'
import { Box } from '@mui/material'
import Products from '../Components/Products/Products'

export default function JournalsPage() {
  return (
    <Box>
      <Products title={"Journals"} collectionName={"journals"}/>
    </Box>
  )
}
