import React from 'react'
import EditZones from '../ConsoleComponents/EditZones/EditZones'
import withAuthorization from '../HOC/withAuthorization'

const ConsoleShippingZonePage = () => {
  return (
    <div>
      <EditZones />
    </div>
  )
}

export default withAuthorization(ConsoleShippingZonePage)