import { Button, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useStyles from './styles';
import CreateIcon from '@mui/icons-material/Create';
import React, { useState } from 'react'
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { useHistory } from 'react-router';
import { NumericFormatCustom } from '../../Components/NumericFormatCustom/NumericFormatCustom';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from '../../Providers/SnackbarProvider/SnackbarProvider';
import { productTypes } from '../../productTypes';

export default function AddShipping () {
  const [disabled, setDisabled] = useState(false)
  const [size, setSize] = useState("")
  const [basePrice, setBasePrice] = useState("")
  const [EU, setEU] = useState("")
  const [WZ1, setWZ1] = useState("")
  const [WZ2, setWZ2] = useState("")
  const [WZ3, setWZ3] = useState("")
  ////////////////////////////// product types /////////////////////////////////

  const [productType, setProductType] = useState(productTypes[0].value);
  //////////////////////////////////////////////////////////////////////////////

  const db = getFirestore()
  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()
  
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    // Need some validation
    setDisabled(true)

    await setDoc(doc(db, `${productType}_shipping_sizes`, `${size.trim()}`), {
      BP: basePrice,
      EU,
      WZ1,
      WZ2,
      WZ3
    })
    setDisabled(false)

    history.push('/console/shipping')

    snackbar.showSnackbar({
      severity: 'success',
      message: 'Successfully created shipping size',
      autoHideDuration: 2000,
    })

  }

  // const validate = () => {
    
  // }

  return (
    <Box className={classes.mainBody} onSubmit={handleSubmit} component="form">
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Add Shipping Size
      </Typography>
      <Stack spacing={4}>
        {/* Product Type */}
        <TextField
          disabled={disabled}
          required
          name="productType"
          margin="dense"
          id="productType"
          select
          label="Product Type"
          fullWidth
          value={productType}
          onChange={e => setProductType(e.target.value)}
        >
          {productTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                  {option.label}
              </MenuItem>
          ))}
        </TextField>

        <TextField
          disabled={disabled}
          required
          name="sizeName"
          margin="dense"
          id="sizeName"
          autoComplete='off'
          label="Size Name"
          value={size}
          onChange={e => setSize(e.target.value)}
          fullWidth
        />

        <TextField
          disabled={disabled}
          name="price"
          required
          label="Base Shipping Price"
          variant="outlined"
          value={basePrice}
          onChange={setBasePrice}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        <TextField
          disabled={disabled}
          name="price"
          required
          label="European Shipping Price"
          variant="outlined"
          value={EU}
          onChange={setEU}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        <TextField
          disabled={disabled}
          name="WZ1"
          required
          label="World Zone 1"
          variant="outlined"
          value={WZ1}
          onChange={setWZ1}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        <TextField
          disabled={disabled}
          name="WZ2"
          required
          label="World Zone 2"
          variant="outlined"
          value={WZ2}
          onChange={setWZ2}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />
        
        <TextField
          disabled={disabled}
          name="WZ3"
          required
          label="World Zone 3"
          variant="outlined"
          value={WZ3}
          onChange={setWZ3}
          InputProps={{
            inputComponent: NumericFormatCustom
          }}
        />

        <Button
          disabled={disabled}
          fullWidth 
          color="primary" 
          variant="contained" 
          startIcon={<CreateIcon />}
          sx={{
            marginTop: '20px',
            marginBottom: '20px'
          }}
          type='submit'
        >
          Add Shipping Size
        </Button>
      </Stack>

    </Box>
  )
}
