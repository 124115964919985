import React, { useState } from 'react'
import { useRef } from 'react'
import useStyles from './styles';
import { Box, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ColorButton from '../PrimaryButton/PrimaryButton';
import emailjs from '@emailjs/browser';
import { useSnackbar } from '../../Providers/SnackbarProvider/SnackbarProvider';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Contact() {
  const classes = useStyles()
  const form = useRef();
  const snackbar = useSnackbar();
  const [disabled, setDisabled] = useState(false)
  const captchaRef = useRef(null)

  const sendEmail = (e) => {
    e.preventDefault();
    setDisabled(true);
  
    if (!validate()) {
      setDisabled(false);
      return;
    }
  
    const token = captchaRef.current.getValue();
  
    const emailParams = {
      name: form.current['name'].value,
      email: form.current['email'].value,
      subject: form.current['subject'].value,
      message: form.current['message'].value,
      'g-recaptcha-response': token,
    };
  
    emailjs
      .send('artbykynaat', 'contact', emailParams, '6LCGw0Fr2u0NAIhP7')
      .then(
        (result) => {
          snackbar.showSnackbar({
            severity: 'success',
            message: 'We have received your email and will get back to you shortly 😄',
            autoHideDuration: 10000,
          });
        },
        (error) => {
          snackbar.showSnackbar({
            severity: 'error',
            message: 'An unexpected error occurred. Please contact me directly at artbykynaat@gmail.com',
            autoHideDuration: 0,
          });
        }
      );
  };


  const validate = () => {
    const formData = new FormData(form.current);

    let msg = ''
    if (!formData.get('name')) {
      msg = 'Please enter a name'
    }
    else if (!formData.get('email')) {
      msg = 'Please enter an email'
    }
    else if (!formData.get('subject')) {
      msg = 'Please enter a subject'
    }
    else if (!formData.get('message')) {
      msg = 'Please enter a message'
    }
    else if (!captchaRef.current.getValue()) {
      msg = 'Please complete the captcha'
    }

    if (msg) {
      snackbar.showSnackbar({
        severity: 'error',
        message: msg,
        autoHideDuration: 4000,
      })
      return false
    }

    return true
  }
  return (
    <Box className={classes.mainBody}>
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Contact Us
      </Typography>
      <form ref={form}>
        <TextField
          disabled={disabled}
          required
          name="name"
          margin="dense"
          id="productName"
          label="Name"
          // value={productName}
          // onChange={e => setProductName(e.target.value)}
          fullWidth
        />

        <TextField
          disabled={disabled}
          required
          name="email"
          margin="dense"
          id="productName"
          label="Email"
          // value={productName}
          // onChange={e => setProductName(e.target.value)}
          fullWidth
        />
        <TextField
          disabled={disabled}
          required
          name="subject"
          margin="dense"
          id="productName"
          autoComplete='off'
          label="Subject"
          // value={productName}
          // onChange={e => setProductName(e.target.value)}
          fullWidth
        />

        <TextField
          disabled={disabled}
          name='message'
          required
          margin="dense"
          multiline
          label="Message"
          rows={3}
          // value={productDesc}
          // onChange={e => setProductDesc(e.target.value)}
          fullWidth
          sx={{
            whiteSpace: 'pre-line'
          }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
          <ReCAPTCHA
            sitekey={'6Lc4-ecnAAAAAHxFQozrq6pgpBWxbhG2hhPMnTPT'} 
            ref={captchaRef}
          />
        </Box>

        {/* Submit button */}
        <ColorButton
          disabled={disabled}
          onClick={sendEmail} 
          fullWidth 
          color="primary" 
          variant="contained" 
          endIcon={<SendIcon />}
          sx={{
            marginTop: '20px',
            marginBottom: '20px'
          }}
        >
          Send
        </ColorButton>
      </form>


    </Box>
  )
}
