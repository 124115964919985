import { Button, Stack } from '@mui/material'
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import InstagramIcon from '@mui/icons-material/Instagram';
import WebIcon from '@mui/icons-material/Web';
import PinterestIcon from '@mui/icons-material/Pinterest';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { Link } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import { Box } from '@mui/system';

export default function Console() {
  const history = useHistory()
  const API = '/.netlify/functions'

  useEffect(() => {
    const fun = async () => {
      const res = await fetch(`${API}/getOrders`, {
        method: 'GET',
      })
      const data = await res.json()
      console.log(data)
    }
    fun()
  }, [])
  

  // add the main site button
  // leave these existing buttons here
  // add manage payments button
  // add instagram button
  // add contact email below it all (personal email)
  // 
  return (
    <Stack 
      spacing={'15px'} 
      sx={{ 
        margin: '60px 60px', 
        '@media screen and (max-width: 780px)': {
          margin: '60px 10px'
        } 
      }}
    >
      <Button
        startIcon={<StorefrontIcon />}
        variant='contained'
        fullWidth
        onClick={() => { history.push('/console/products') }}
      >
        Products
      </Button>

      <Box
        sx={{ 
          display: 'flex', 
          flexDirection: 'row',
          '@media screen and (max-width: 600px)': {
            flexDirection: 'column'
          }
        }}
      >
        <Button
          startIcon={<LocalShippingIcon />}
          variant='contained'
          fullWidth
          onClick={() => { history.push('/console/shipping') }}
          sx={{ 
            marginRight: '10px',
            '@media screen and (max-width: 600px)': {
              marginRight: '0px',
              margin: '15px 0px'
            }
          }}
        >
          Shipping
        </Button>
        <Button
          startIcon={<LocalOfferIcon />}
          variant='contained'
          fullWidth
          onClick={() => { history.push('/console/coupons') }}
          sx={{ 
            marginLeft: '10px',
            '@media screen and (max-width: 600px)': {
              marginLeft: '0px',
              margin: '15px 0px'
            }
          }}
        >
          Coupons
        </Button>
      </Box>

      <Box>
        <Button
          startIcon={<PointOfSaleIcon />}
          variant='contained'
          fullWidth
          sx={{ 
            background: '#5e61f6',
            ':hover': {
              background: '#4b4dc7'
            },
            '@media screen and (max-width: 600px)': {
              marginBottom: '20px'
            }
          }}
          component={Link}
          to={{ pathname: "https://dashboard.stripe.com/" }} 
          target="_blank"
        >
          Manage Payments
        </Button>
      </Box>

      <Box
        sx={{ 
          display: 'flex', 
          flexDirection: 'row',
          '@media screen and (max-width: 600px)': {
            flexDirection: 'column'
          }
        }}
      >
        <Button
          variant='contained'
          fullWidth
          sx={{
            backgroundColor: '#DBB793',
            marginRight: '10px',
            ':hover': {
              backgroundColor: '#bf9b78',
            },
            '@media screen and (max-width: 600px)': {
              marginLeft: '0px',
              marginBottom: '25px'
            }
          }}
          startIcon={<WebIcon />}
          component={Link}
          to={{ pathname: "https://artbykynaat.com" }} 
          target="_blank"
        >
          My Website
        </Button>

        <Button
          variant='contained'
          fullWidth
          sx={{
            background : 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)',
            marginLeft: '10px',
            marginRight: '10px',
            '@media screen and (max-width: 600px)': {
              marginLeft: '0px',
              marginRight: '0px',
              marginBottom: '25px'
            }
          }}
          startIcon={<InstagramIcon />}
          component={Link}
          to={{ pathname: "https://www.instagram.com/artbykynaat/" }} 
          target="_blank"
        >
          My Instagram
        </Button>
        <Button
          variant='contained'
          fullWidth
          sx={{
            background : '#E60023',
            marginLeft: '10px',
            ':hover': {
              backgroundColor: '#b3021d',
            },
            '@media screen and (max-width: 600px)': {
              marginLeft: '0px',
              marginBottom: '25px'
            }
          }}
          startIcon={<PinterestIcon />}
          component={Link}
          to={{ pathname: "https://www.pinterest.com/artbykynaat/" }} 
          target="_blank"
        >
          My Pinterest
        </Button>

      </Box>
      
    </Stack>
  )
}
