import { Button, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import CreateIcon from '@mui/icons-material/Create';
import useStyles from './styles';
import { useHistory } from 'react-router';

export default function AddCoupons() {
  const API = '/.netlify/functions'
  const history = useHistory()
  const [disabled, setDisabled] = useState(false)
  const [couponCode, setCouponCode] = useState(null)
  const [name, setName] = useState(null)
  const [percentOff, setPercentOff] = useState(null)
  const classes = useStyles()
  
  const handleSubmit = async () => {
    // some simple validation
    try {
      setDisabled(true)
      await fetch(`${API}/addCoupon`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({
          id: couponCode,
          name: name,
          percent_off: percentOff,
        })
      })
      history.push('/console/coupons')
    } catch (e) {
      console.log(e)
      setDisabled(false)
    }
  }

  // const validate = () => {

  // }

  return (
    <Box className={classes.mainBody}>
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Add Coupon
      </Typography>
      <Stack spacing={4} marginBottom='20px'>
        <TextField
          disabled={disabled}
          required
          name="couponCode"
          margin="dense"
          id="couponCode"
          autoComplete='off'
          label="Coupon Code"
          value={couponCode}
          onChange={e => setCouponCode(e.target.value)}
          fullWidth
        />

        <TextField
          disabled={disabled}
          required
          name="name"
          margin="dense"
          id="name"
          autoComplete='off'
          label="Name"
          value={name}
          onChange={e => setName(e.target.value)}
          fullWidth
        />

        <TextField
          disabled={disabled}
          required
          name="percentOff"
          margin="dense"
          id="name"
          autoComplete='off'
          label="Percent Off"
          value={percentOff}
          onChange={e => setPercentOff(e.target.value)}
          fullWidth
          type='number'
        />

        <Button
          disabled={disabled}
          onClick={handleSubmit} 
          fullWidth 
          color="primary" 
          variant="contained" 
          startIcon={<CreateIcon />}
        >
          Add Coupons
        </Button>
      </Stack>

    </Box>
  )
}
