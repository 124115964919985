import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from './styles';

export default function CouponTable () {
  const API = '/.netlify/functions'
  const [coupons, setCoupons] = useState([])

  const classes = useStyles()

  const handleCouponDelete = async (coupon) => {
    await fetch(`${API}/deleteCoupon`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: coupon.id,
      })
    })
    let filteredCoupons = coupons.filter(item => item.id !== coupon.id)
    console.log(filteredCoupons)
    setCoupons(filteredCoupons)
  }

  useEffect(() => {
    const fun = async () => {
      const res = await fetch(`${API}/getCoupons`, {
        method: 'GET',
      })
      const data = await res.json()
      console.log(data)
      setCoupons(data.coupons)
    }

    fun()
  }, [])

  return (
    <Box className={classes.mainBody}>
      <Typography variant='h4' align='center' gutterBottom marginTop={1}>
        Coupons
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Coupon Code</TableCell>
              <TableCell align="left">Percent Off</TableCell>
              <TableCell align="left">Times Redeemed</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon, idx) => (
              <TableRow key={idx}>
                <TableCell align="left">{coupon.id}</TableCell>
                <TableCell align="left">{coupon.percent_off}%</TableCell>
                <TableCell align="left">{coupon.times_redeemed}</TableCell>
                <TableCell align="left">
                  <Stack direction="row" spacing={1}>
                    <Button
                      color='error'
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      onClick={() => { handleCouponDelete(coupon, idx) } }
                    >
                      Delete
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
