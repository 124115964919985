import React, { createContext, useContext, useState } from 'react';
import Notification from '../../Components/Notification/Notification';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbarData, setSnackbarData] = useState(null);

  const showSnackbar = (data) => {
    setSnackbarData(data);
  };

  const hideSnackbar = () => {
    setSnackbarData(null);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      {snackbarData && (
        <Notification data={snackbarData} />
      )}
    </SnackbarContext.Provider>
  );
};
