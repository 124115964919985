import React from 'react'
import EditShipping from '../ConsoleComponents/EditShipping/EditShipping'
import withAuthorization from '../HOC/withAuthorization'

const ConsoleEditShippingPage = () => {
  return (
    <EditShipping />
  )
}

export default withAuthorization(ConsoleEditShippingPage)