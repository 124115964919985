import React, { useState, createContext, useEffect } from 'react';
import { getAuth } from "firebase/auth";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState('loading');
  

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(setCurrentUser);

    return () => {
      unsubscribe(); // Remove the listener to clean up
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser}}> 
      {children} 
    </AuthContext.Provider>
  )
}
