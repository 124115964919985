import React from 'react'
import Products from '../Components/Products/Products'
import { Box } from '@mui/material'

export default function PrintsPage() {
  return (
    <Box>
      <Products title={"Prints"} collectionName={"prints"}/>
    </Box>
  )
}
