import { Box } from '@mui/system';
import React from 'react'
import AddProduct from '../ConsoleComponents/AddProduct/AddProduct';
import withAuthorization from '../HOC/withAuthorization';

const ConsoleNewProductPage = () => {
  return (
    <Box>
      <AddProduct />
    </Box>
  );
}

export default withAuthorization(ConsoleNewProductPage)