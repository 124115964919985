import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import Product from '../Components/Product/Product'

export default function ProductPage({ addToCartNum }) {
  let { productType, productName } = useParams()
  return (
    <Box>
      <Product addToCartNum={addToCartNum} productType={productType} productName={productName}/>
    </Box>
  )
}
