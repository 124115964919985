import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ConsoleProductCard from '../ConsoleProductCard/ConsoleProductCard'
// import popularImage1 from '../../assets/images/popularImage1.jpeg' 
// import popularImage2 from '../../assets/images/popularImage2.jpeg' 
// import popularImage3 from '../../assets/images/popularImage3.jpeg' 
// import popularImage4 from '../../assets/images/popularImage4.jpeg' 
import useStyles from './styles';
import { collection, getDocs, getFirestore } from '@firebase/firestore';
import { useHistory } from 'react-router'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import withLoading from '../../HOC/withLoading';
import { productTypes } from '../../productTypes';

const ConsoleProducts = ({ loading, setLoading }) => {
    const classes = useStyles()
    const history = useHistory()

    const [productType, setProductType] = useState(productTypes[0].value);

    const [DBProducts, setDBProducts] = useState([])
    useEffect(() => {
      const fun = async () => {
        const db = getFirestore()
        const products = await getDocs(collection(db, productType))

        const allProducts = (products.docs.map((doc) => (
          { ...doc.data(), 
            productType: productType, 
            docId: doc.id,
            docRef: doc.ref.id
          }
        )))

        setDBProducts(allProducts)
        setLoading(false)
      }
  
      fun()
    }, [productType])
  

    return (
      <Box className={classes.outBox}>
        <Box>
          <Typography variant='h4' align='center' gutterBottom marginTop={1} mb={1}>
            My Products
          </Typography>
          <Box marginRight={'20px'} marginLeft={'20px'} paddingBottom={'20px'}>
            <TextField
              name="productType"
              margin="dense"
              id="standard-select-product"
              select
              label="Product Type"
              fullWidth
              value={productType}
              onChange={e => setProductType(e.target.value)}
            >
              {productTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                      {option.label}
                  </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box marginRight={'20px'} marginLeft={'20px'} paddingBottom={'20px'}>
            <Grid container spacing={4}>
              {
                DBProducts.map((product, idx) => (productType === product.productType &&
                  <Grid key={idx} item xs={12} sm={6} md={6} lg={3} >
                    <ConsoleProductCard 
                      productName={product.docRef}
                      productType={product.productType}
                      image={product.thumbnail ? `https://cdn.artbykynaat.com/${product.thumbnail}`: null} 
                      price={product.price} 
                      title={product.name} 
                      active={product.active} 
                      docRef={product.productType + "/" + product.docId}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Box>
        <Button
          startIcon={<AddCircleOutlineOutlinedIcon />}
          
          sx={{
            position: 'fixed', 
            bottom: '10px', 
            right: '10px',
            minWidth: '215px'
          }}
          color='success'
          variant='contained'
          onClick={() => { history.push('/console/products/add') }}
        >
          New Product
        </Button>
      </Box>
  
    )
}

export default withLoading(ConsoleProducts)